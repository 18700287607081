import { toRefs, reactive } from 'vue';
import { useSdk } from './use-mycure';
import { useFacilities } from './use-facilities';
import { formatRecord } from '@/utils/formatters';

const SERVICE_NAME = 'medical-records';
const RECORD_TYPE = 'summary';
const RECORD_SUBTYPE_ENCOUNTER = 'encounter';
const RECORD_SUBTYPE_PATIENT = 'patient';

const state = reactive({
  encounterSummary: {},
  patientSummary: {},
});

export function useAiSummary () {
  const sdk = useSdk();
  const { getActiveFacility } = useFacilities();

  async function getEncounterSummary (encounterId) {
    const query = {
      type: RECORD_TYPE,
      subtype: RECORD_SUBTYPE_ENCOUNTER,
      encounter: encounterId,
    };

    const res = await sdk?.get(SERVICE_NAME, query);
    const record = formatRecord(res);
    state.encounterSummary = record;
    return record;
  }

  async function getPatientSummary (patientId) {
    const activeFacility = await getActiveFacility();
    const facilityId = activeFacility?.id;
    if (!facilityId) return;

    const query = {
      type: RECORD_TYPE,
      subtype: RECORD_SUBTYPE_PATIENT,
      facility: facilityId,
      patient: patientId,
    };

    const res = await sdk?.get(SERVICE_NAME, query);
    const record = formatRecord(res);
    state.patientSummary = record;
    return record;
  }

  async function createEncounterSummary (encounterId) {
    const payload = {
      type: RECORD_TYPE,
      subtype: RECORD_SUBTYPE_ENCOUNTER,
      encounter: encounterId,
    };

    const res = await sdk?.create(SERVICE_NAME, payload);
    const record = formatRecord(res);
    state.encounterSummary = record;
    return record;
  }

  async function createPatientSummary (patientId) {
    const activeFacility = await getActiveFacility();
    const facilityId = activeFacility?.id;
    if (!facilityId) return;

    const payload = {
      type: RECORD_TYPE,
      subtype: RECORD_SUBTYPE_PATIENT,
      facility: facilityId,
      patient: patientId,
    };

    const res = await sdk?.create(SERVICE_NAME, payload);
    const record = formatRecord(res);
    state.patientSummary = record;
    return record;
  }

  async function regenerateSummary ({ recordId, encounterId, patientId }) {
    await sdk?.delete(SERVICE_NAME, recordId);
    state.encounterSummary = {};
    if (encounterId) await createEncounterSummary(encounterId);
    if (patientId) await createPatientSummary(patientId);
  }

  async function update ({ recordId, type, summary, finalize }) {
    const payload = {
      summary: {
        text: summary,
      },
    };

    if (finalize) payload.finalize = true;

    const res = await sdk?.update(SERVICE_NAME, recordId, payload);
    const record = formatRecord(res);
    if (type === 'encounter') state.encounterSummary = record;
    if (type === 'patient') state.patientSummary = record;
    return record;
  }

  return {
    ...toRefs(state),
    getEncounterSummary,
    getPatientSummary,
    createEncounterSummary,
    createPatientSummary,
    regenerateSummary,
    update,
  };
}
